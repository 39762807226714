import * as React from 'react';
import Layout from './components/Layout';
import './custom.css';
import MemberSearch from './components/member-search/MemberSearch';

import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
  MsalProvider,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { getMsalInstance } from './authConfig';
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionType,
  PublicClientApplication,
} from '@azure/msal-browser';
import NavMenu from './components/NavMenu';

export default () => {
  const [msalInstance, setMsalInstance] =
    React.useState<PublicClientApplication | null>(null);
  React.useEffect(() => {
    (async () => {
      try {
        const msalInstance = await getMsalInstance();
        msalInstance?.addEventCallback((event: EventMessage) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
          }
        });
        setMsalInstance(msalInstance);
      } catch (error) {
        console.error((error as Error).message);
      }
    })();
  }, []);

  if (!msalInstance) {
    return null;
  }

  return (
    <Layout>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate
          interactionType={InteractionType.Redirect}
          errorComponent={ErrorComponent}
        />
        <AuthenticatedTemplate>
          <MemberSearch msalInstance={msalInstance} />
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <NavMenu></NavMenu>
        </UnauthenticatedTemplate>
      </MsalProvider>
    </Layout>
  );
};
const ErrorComponent = (props: any) => (
  <h5 className="card-title">{JSON.stringify(props)}</h5>
);
