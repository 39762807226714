import * as React from 'react';
import {
  Collapse,
  Container,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {
  useIsAuthenticated,
  useMsal,
  useMsalAuthentication
} from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';

const NavMenu = () => {
  const { instance: authInstance } = useMsal();  
  const isAuthenticated = useIsAuthenticated();
  const {login} =useMsalAuthentication(InteractionType.Redirect);
  

  return (
    <header>
      <Navbar
        className="navbar-expand-sm navbar-toggleable-sm border-bottom box-shadow mb-3"
        light
      >
        <Container>
          <NavbarBrand tag={Link} to="/">
            <img src="flg-logo.webp" width="200" />
          </NavbarBrand>
          <NavbarBrand>Clubware Photo Uploader</NavbarBrand>
          <NavbarToggler className="mr-2" />
          <Collapse className="d-sm-inline-flex flex-sm-row-reverse" navbar>
            <ul className="navbar-nav flex-grow">
              {isAuthenticated && (
                <NavItem>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      authInstance.logout();
                    }}
                  >
                    Logout
                  </button>
                </NavItem>
              )}
              {!isAuthenticated && (
                <NavItem>
                  <button className="btn btn-primary" onClick={()=>{
                    login();
                  }}>
                    Login
                  </button>
                </NavItem>
              )}
            </ul>
          </Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default NavMenu;
