import React, { useState, useRef, useCallback, useEffect } from 'react';
import Measure from 'react-measure';
import styled, { css, keyframes } from 'styled-components';
import { Button } from 'reactstrap';

interface Props {
  onCapture: (blob: Blob) => void;
  onClear: () => void;
}

const CAPTURE_OPTIONS = {
  audio: false,
  video: { facingMode: 'environment' },
};

const flashAnimation = keyframes`
  from {
    opacity: 0.75;
  }

  to {
    opacity: 0;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: ${(maxWidth: any) => maxWidth && `${maxWidth}px`};
  max-height: ${(maxHeight: any) => maxHeight && `${maxHeight}px`};
`;

export const Canvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
`;

export const Video = styled.video`
  position: absolute;

  &::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 20px;
  left: 20px;
  box-shadow: 0px 0px 20px 56px rgba(0, 0, 0, 0.6);
  border: 1px solid #ffffff;
  border-radius: 10px;
`;

export const Flash = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0;
  background-color: #ffffff;

  ${(flash: any) => {
    if (flash) {
      return css`
        animation: ${flashAnimation} 750ms ease-out;
      `;
    }
  }}
`;

export const StyledButton = styled(Button)`
  width: 75%;
  min-width: 100px;
  max-width: 250px;
  margin-top: 24px;
  padding: 12px 24px;
`;
const useCardRatio = (initialRatio: number) => {
  const [aspectRatio, setAspectRatio] = useState(initialRatio);

  const calculateRatio = useCallback((height: number, width: number) => {
    if (height && width) {
      const isLandscape = height <= width;
      const ratio = isLandscape ? width / height : height / width;

      setAspectRatio(ratio);
    }
  }, []);

  return [aspectRatio, calculateRatio];
};

const useUserMedia = (requestedMedia: MediaStreamConstraints) => {
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);

  useEffect(() => {
    async function enableStream() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia(
          requestedMedia
        );
        setMediaStream(stream);
      } catch (err) {
        // Removed for brevity
      }
    }

    if (!mediaStream) {
      enableStream();
    } else {
      return function cleanup() {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      };
    }
  }, [mediaStream, requestedMedia]);

  return mediaStream;
};

const useOffsets = (
  vWidth: number,
  vHeight: number,
  cWidth: number,
  cHeight: number
) => {
  const [offsets, setOffsets] = useState({ x: 0, y: 0 });

  useEffect(() => {
    if (vWidth && vHeight && cWidth && cHeight) {
      const x = vWidth > cWidth ? Math.round((vWidth - cWidth) / 2) : 0;

      const y = vHeight > cHeight ? Math.round((vHeight - cHeight) / 2) : 0;

      setOffsets({ x, y });
    }
  }, [vWidth, vHeight, cWidth, cHeight]);

  return offsets;
};

export function CapturePhoto({ onCapture, onClear }: Props) {
  const canvasRef = useRef();
  const videoRef = useRef();

  const [container, setContainer] = useState({ width: 0, height: 0 });
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isCanvasEmpty, setIsCanvasEmpty] = useState(true);
  const [isFlashing, setIsFlashing] = useState(false);

  const mediaStream = useUserMedia(CAPTURE_OPTIONS);
  const [aspectRatio, calculateRatio] = useCardRatio(1.586);
  const offsets = useOffsets(
    // @ts-ignore: Object is possibly 'undefined'.
    videoRef.current && videoRef.current.videoWidth,
    // @ts-ignore: Object is possibly 'undefined'.
    videoRef.current && videoRef.current.videoHeight,
    container.width,
    container.height
  );
  // @ts-ignore: Object is possibly 'undefined'.
  if (mediaStream && videoRef.current && !videoRef.current.srcObject) {
    // @ts-ignore: Object is possibly 'undefined'.
    videoRef.current.srcObject = mediaStream;
  }

  function handleResize(contentRect: any) {
    setContainer({
      width: contentRect.bounds.width,
      // @ts-ignore: Object is possibly 'undefined'.
      height: Math.round(contentRect.bounds.width / aspectRatio),
    });
  }

  function handleCanPlay() {
    // @ts-ignore: Object is possibly 'undefined'.
    calculateRatio(videoRef.current.videoHeight, videoRef.current.videoWidth);
    setIsVideoPlaying(true);
    // @ts-ignore: Object is possibly 'undefined'.
    videoRef.current.play();
  }

  function handleCapture() {
    handleClear();
    // @ts-ignore: Object is possibly 'undefined'.
    const context = canvasRef.current.getContext('2d');
    
    context.drawImage(
      videoRef.current,
      offsets.x,
      offsets.y,
      container.width,
      container.height,
      0,
      0,
      container.width,
      container.height
    );
    if (canvasRef && canvasRef.current) {
      // @ts-ignore: Object is possibly 'undefined'.
      canvasRef.current.toBlob(
        (blob: Blob) => onCapture(blob),
        'image/jpeg',
        1
      );
      //canvasRef.current.toBlob((blob: Blob) => `data:image/jpeg;base64,${blob}`, "image/jpeg", 1);
      setIsCanvasEmpty(false);
      setIsFlashing(true);
    }
  }

  function handleClear() {
    // @ts-ignore: Object is possibly 'undefined'.
    const context = canvasRef.current.getContext('2d');
    // @ts-ignore: Object is possibly 'undefined'.
    context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setIsCanvasEmpty(true);
    onClear();
  }

  if (!mediaStream) {
    return null;
  }

  return (
    <Measure bounds onResize={handleResize}>
      {({ measureRef }) => (
        <Wrapper>
          <Container
            // @ts-ignore: Object is possibly 'undefined'.
            ref={measureRef}
            // @ts-ignore: Object is possibly 'undefined'.
            maxHeight={videoRef.current && videoRef.current.videoHeight}
            // @ts-ignore: Object is possibly 'undefined'.
            maxWidth={videoRef.current && videoRef.current.videoWidth}
            style={{
              height: `${container.height}px`,
            }}
          >
            <Video
              id="video"
              // @ts-ignore: Object is possibly 'undefined'.
              ref={videoRef}
              hidden={!isVideoPlaying}
              onCanPlay={handleCanPlay}
              autoPlay
              playsInline
              muted
              style={{
                top: `-${offsets.y}px`,
                left: `-${offsets.x}px`,
              }}
            />

            <Overlay hidden={!isVideoPlaying} />

            <Canvas
              // @ts-ignore: Object is possibly 'undefined'.
              ref={canvasRef}
              width={container.width}
              height={container.height}
            />

            <Flash
              // @ts-ignore: Object is possibly 'undefined'.
              flash={isFlashing}
              onAnimationEnd={() => setIsFlashing(false)}
            />
          </Container>

          {isVideoPlaying && (
            <StyledButton color="primary" onClick={isCanvasEmpty ? handleCapture : handleClear}>
              {isCanvasEmpty ? 'Take a photo' : 'Take another photo'}
            </StyledButton>
          )}
        </Wrapper>
      )}
    </Measure>
  );
}
