import * as React from 'react';
import { Member } from '../../types/member';
import MemberPhoto from '../member-photo/MemberPhoto';
import { Button } from 'reactstrap';
import { useAuth } from '../../authConfig';
import { useHistory } from 'react-router-dom';

interface Props {
  member: Member;
}

const createUrl = (blob?: Blob) =>
  blob ? `data:image/jpeg;base64,${blob}` : null;

const PhotoButton = ({ member }: Props) => {
  const history = useHistory();
  const { acquireReadToken } = useAuth();
  const [photoUrl, setPhotoUrl] = React.useState('');
  const fetchPhoto = async () => {
    try {
      const accessToken = await acquireReadToken();
      // @ts-ignore: Object is possibly 'undefined'.
      async function fetchData() {
        const response = await fetch(`api/members/${member.memberId}/photo`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });
        if (response.status === 200) {
          const data = await response.json();
          const photo = (await data) && data.photo ? data.photo : null;
          const photoUrlCreated = createUrl(photo);
          if (photoUrlCreated) {
            setPhotoUrl(photoUrlCreated);
          } else {
            setPhotoUrl('');
          }
        } else if (response.status === 401) {
          setPhotoUrl('');
          history.push('/');
        } else {
          setPhotoUrl('');
        }
      }
      fetchData();
    } catch (err) {
      history.push('/');
    }
  };

  const [show, setShow] = React.useState(false);
  const handleClose = () => {
    setPhotoUrl('');
    setShow(false);
  };
  const handleShow = () => {
    fetchPhoto();
    setShow(true);
  };
  if (!member) {
    return <div></div>;
  }

  return (
    <div>
      <div className="profile-button p-0 m-0">
        <div className="pt-2 clearfix">
          <Button color="primary" size="sm" onClick={handleShow}>
            View/Capture
          </Button>
        </div>
      </div>
      <MemberPhoto
        show={show}
        onClose={handleClose}
        memberId={member.memberId}
        existingImageUrl={photoUrl}
        memberName={`${member.firstName ? member.firstName : ''} ${
          member.lastName
        }`}
      />
    </div>
  );
};

export { PhotoButton };
