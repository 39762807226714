import React from 'react';
import './MemberPhoto.css';
import {
  Container,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from 'reactstrap';
import { CapturePhoto } from '../capture-photo/CapturePhoto';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../../authConfig';
import { useHistory } from 'react-router-dom';

export interface MemberPhotoProps {
  show: boolean;
  onClose: () => void;
  animation?: boolean;
  memberId: string;
  existingImageUrl?: string;
  memberName?: string;
}

let photoBlob: Blob = new Blob();
let memId: string = '';
const renderCapturePhoto = () => {
  photoBlob = new Blob();
  return (
    <div className="min-width">
      <CapturePhoto
        onCapture={(blob: any) => captureImage(blob)}
        onClear={clearImage}
      />
    </div>
  );
};
const captureImage = (blob: Blob) => {
  photoBlob = blob;
};

const clearImage = () => {
  photoBlob = new Blob();
};

const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

const renderExistingPhoto = (photoUrl?: string) => {
  if (photoUrl && photoUrl != null && photoUrl !== '') {
    return <img src={photoUrl} width="400" />;
  } else
    return (
      <div className="min-width">
        <div className="align-center-no-photo">
          <h4>No photo</h4>
          <FontAwesomeIcon
            icon={faUserCircle}
            size="10x"
            className="d-inline-block align-middle ml-2"
          />
        </div>
      </div>
    );
};

const MemberPhoto = (props: MemberPhotoProps) => {
  const history = useHistory();
  const { show, onClose, memberId, existingImageUrl, memberName } = props;
   const {acquireModifyToken} = useAuth();

  memId = memberId;
  const savePhoto = async() => {
      if (photoBlob && photoBlob != null) {
        
        try {
          const accessToken = await acquireModifyToken();

          blobToBase64(photoBlob).then(async (res) => {
            let base64string = '';
            if (res) {
              // @ts-ignore: Object is possibly 'undefined'.
              let arr = res.split(',');
              base64string = arr[1];
            }
            const payload = {
              memberId: memId,
              photo: base64string,
            };
            const response = await fetch(`api/members/photo`, {
              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
              },
              body: JSON.stringify(payload),
            });
            if (response.status === 204) {
              onClose();
            } else if (response.status === 404) {
              console.error('response', response);
              alert('Not Found');
            } else if (response.status === 400) {
              console.error('response', response);
              alert(
                'No photo has been taken!  Please take a new photo and click the Save Photo button.'
              );
            } else if (response.status === 401) {
              history.push('/');
            } else {
              console.error('response', response);
              alert('Server Error');
            }
          });
        } catch (err) {
          history.push('/');
        }
      }
    
  };
  return (
    <Modal isOpen={show} className="modal-width">
      <ModalHeader>
        Member Photo {memberName ? `of ${memberName}` : ``}
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row className="modal-height">
            <Col sm={6}>{renderExistingPhoto(existingImageUrl)}</Col>
            <Col sm={6}>{renderCapturePhoto()}</Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onClose}>
          Close
        </Button>

        <Button color="primary" onClick={savePhoto}>
          Save Photo
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MemberPhoto;
