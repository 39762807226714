import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import FormControl from 'react-bootstrap/FormControl';
import Spinner from 'react-bootstrap/Spinner';
import * as React from 'react';
import { Row, Col, Container } from 'reactstrap';
import { PhotoButton } from '../photo-button/PhotoButton';
import { Member } from '../../types/member';
import moment from 'moment';
import './MemberSearch.css';
import { Link, useHistory } from 'react-router-dom';
import NavMenu from '../NavMenu';
import { useAuth } from '../../authConfig';

const columns = [
    {
        name: 'First Name',
        selector: (row: any) => row.firstName,
        sortable: true,
    },
    {
        name: 'Last Name',
        selector: (row: any) => row.lastName,
        sortable: true,
    },
    {
        name: 'Date of Birth',
        selector: (row: any) => row.dateOfBirthFormatted,
        sortable: false,
    },
    {
        name: 'Gender',
        selector: (row: any) => row.gender,
        sortable: false,
        width: '60px',
    },
    {
        name: 'Email Address',
        selector: (row: any) => row.email,
        sortable: true,
        width: '250px',
    },
    {
        name: 'Mobile',
        selector: (row: any) => row.mobile,
        sortable: false,
    },
    {
        name: 'Action',
        button: true,
        width: '200px',
        cell: (row: any) => <PhotoButton member={row}></PhotoButton>,
    },
];

const Loader = () => (
    <div>
        <Spinner className="big" animation="border" variant="primary" />
    </div>
);

const MemberSearch = (props: any) => {
    const { acquireReadToken } = useAuth();
    const [members, setMembers] = React.useState([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [firstName, setFirstName] = React.useState('');
    const [lastName, setLastName] = React.useState('');
    const [gender, setGender] = React.useState('');
    const [message, setMessage] = React.useState('');
    const [mobile, setMobile] = React.useState('');
    const [status, setStatus] = React.useState('Active');
    const [clubs, setClubs] = React.useState([]);
    const [branch, setBranch] = React.useState('');


    const clearForm = () => {
        setIsLoading(false);
        setFirstName('');
        setLastName('');
        setGender('');
        setMobile('');
        setMembers([]);
    };
    const history = useHistory();
    const onSearch = async (e: any) => {
        e.preventDefault();
        let queryString = '';
        if (lastName && lastName.length > 1) {
            queryString = `?LastName=${lastName}`;
            if (firstName && firstName.length > 1) {
                queryString = `${queryString}&FirstName=${firstName}`;
            }
            if (gender && gender.length > 0) {
                queryString = `${queryString}&Gender=${gender}`;
            }
            if (mobile && mobile.length > 0) {
                queryString = `${queryString}&Mobile=${mobile}`;
            }
            if (status && status.length > 0) {
                queryString = `${queryString}&Status=${status}`;
            }
            if (branch && branch.length > 0) {
                queryString = `${queryString}&Branch=${branch}`;
            }
        }
        if (queryString !== '') {
            setIsLoading(true);
            try {
                const accessToken = await acquireReadToken();
                const response = await fetch(`api/members${queryString}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response.status === 200) {
                    const data = await response.json();

                    const memberList = (await data) && data.members ? data.members : null;
                    const dateFormat = 'DD-MM-YYYY';
                    const memberListFormatted = memberList.map((member: Member) => {
                        member.dateOfBirthFormatted = moment(member.dateOfBirth).format(
                            dateFormat
                        );
                        return member;
                    });
                    memberList && memberList.length > 0
                        ? setMembers(memberList)
                        : setMembers([]);
                    setMessage('');
                    setIsLoading(false);
                } else if (response.status === 204) {
                    setMembers([]);
                    setMessage('No Results Found!');
                    setIsLoading(false);
                } else if (response.status === 400) {
                    setMembers([]);
                    setMessage('Bad Request');
                    setIsLoading(false);
                    alert('Bad Request');
                } else if (response.status === 401) {
                    setIsLoading(false);
                    history.push('/');
                } else {
                    setMembers([]);
                    setMessage('Server Error!');
                    setIsLoading(false);
                    alert('Server Error');
                }
            } catch (err) {
                console.error(err);
                setIsLoading(false);
                alert('Error! Please try again later.');

                history.push('/');
                // handle error as needed
            }

            setIsLoading(false);
        }
    };
    async function getClubName() {
        try {
            const apiBaseResponse = await fetch('api/base');
            const apiBaseData = await apiBaseResponse.json();
            const clubsResponse = await fetch(`${apiBaseData.clubsApiBaseAddress}/clubs/v1/allclubs`);
            const clubsData = await clubsResponse.json();
            const clubNames = clubsData.clubs.map((club: any) => ({
                branchId: club.clubId,
                name: club.title,
            }));
            setClubs(clubNames);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    React.useEffect(() => {
        getClubName();
    }, []);

    return (
        <div>
            <NavMenu />
            <Container>
                <div className="page-header-panel">
                    <div className="container">
                        <div className="row">
                            <div className="col align-self-center">
                                <h3>Member Search</h3>
                                <hr />
                                <form onSubmit={onSearch}>
                                    <Container>
                                        <Row>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <label>First Name</label>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <FormControl
                                                            onChange={(e: any) =>
                                                                setFirstName(e.target.value)
                                                            }
                                                            className=""
                                                            type="text"
                                                            minLength={2}
                                                            placeholder="First Name"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <label>Last Name *</label>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <FormControl
                                                            onChange={(e: any) => setLastName(e.target.value)}
                                                            className=""
                                                            type="text"
                                                            required
                                                            minLength={2}
                                                            placeholder="Last Name"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <p>
                                                <br /> <br />
                                            </p>
                                        </Row>
                                        <Row>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <label>Gender</label>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <FormControl
                                                            as="select"
                                                            onChange={(e: any) => setGender(e.target.value)}
                                                        >
                                                            <option value="">Select a Gender</option>
                                                            <option value="M">Male</option>
                                                            <option value="F">Female</option>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <label>Mobile</label>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <FormControl
                                                            onChange={(e: any) => setMobile(e.target.value)}
                                                            className=""
                                                            type="tel"
                                                            min="1"
                                                            max="10"
                                                            placeholder="Mobile"
                                                        >
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <p> </p>
                                        <Row>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <label>Club Name</label>
                                                    </Col>
                                                    <Col sm={9}>
                                                        <FormControl
                                                            as="select"
                                                            onChange={(e: any) => setBranch(e.target.value)}>

                                                            <option value="">Select a Club</option>
                                                            {Array.isArray(clubs) && clubs.map((c: any) => (
                                                                <option key={c.branchId} value={c.branchId}>{c.name}</option>
                                                            ))}
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}>
                                                        <label> Active Status</label>
                                                    </Col>
                                                    <Col sm={1}>
                                                        <FormControl
                                                            onChange={(e: any) => setStatus(e.target.checked ? 'Active' : 'Inactive')}
                                                            className="big-checkbox"
                                                            type="checkbox" defaultChecked>

                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <p> </p>
                                        <Row>                                           
                                            <Col sm={6}>
                                                <Row>
                                                    <Col sm={3}></Col>
                                                    <Col sm={9}>
                                                        <Row>
                                                            <Col sm={4}>
                                                                <button
                                                                    type="submit"
                                                                    className="btn btn-primary btn-align"
                                                                    disabled={isLoading}
                                                                >
                                                                    <span>
                                                                        <FontAwesomeIcon
                                                                            icon={faSearch}
                                                                            size="sm"
                                                                        />
                                                                    </span>{' '}
                                                                    <span>Search</span>
                                                                </button>
                                                            </Col>
                                                            <Col sm={4}>
                                                                <button
                                                                    type="reset"
                                                                    className="btn btn-light"
                                                                    onClick={clearForm}
                                                                    disabled={isLoading}
                                                                >
                                                                    <span>Clear</span>
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Container>
                                </form>
                                <hr />
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading && (
                    <div className="parentDisable">
                        <h3 className="spinner-jetts">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </h3>
                    </div>
                )}
                {members && members.length > 99 && (
                    <p>
                        <strong>
                            Only the first 100 results are displayed. For further results,
                            please refine the search criteria.
                        </strong>
                    </p>
                )}
                {message && message !== '' && (
                    <div className="center-align color-red">
                        <h4>{message}</h4>
                    </div>
                )}
                {members && members.length > 0 && (
                    <DataTable
                        title="Member Search Results"
                        key={1}
                        columns={columns}
                        data={members}
                        pagination
                        progressComponent={<Loader></Loader>}
                        noDataComponent={'No Matches Found'}
                    />
                )}
            </Container>
        </div>
    );
};

export default MemberSearch;
